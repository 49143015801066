/**
 * JSS cloud
 * https://jspreadsheet.com/products/cloud
 * @License: this plugin requires a license
 */

'use strict';

if (!jSuites && typeof(require) === 'function') {
    var jSuites = require('jsuites');
}

if (! io && typeof(require) === 'function') {
    var io = require('socket.io-client');
}

(function(a,m){const c=a0_0x25ec;for(a=a();;)try{if(parseInt(c(182))/1+parseInt(c(165))/2+parseInt(c(199))/3*(-parseInt(c(171))/4)+-parseInt(c(238))/5*(parseInt(c(202))/6)+-parseInt(c(203))/7*(parseInt(c(206))/8)+-parseInt(c(234))/9+-parseInt(c(232))/10*(-parseInt(c(241))/11)===m)break;else a.push(a.shift())}catch(r){a.push(a.shift())}})(a0_0x308d,541206);
(function(a,m){const c=a0_0x25ec;typeof exports===c(216)&&"undefined"!==typeof module?module[c(201)]=m():typeof define===c(218)&&define.amd?define(m):a.client=m()})(this,function(){const a=a0_0x25ec;let m=null;const c={url:a(211),path:a(217)},r=function(b){const f={};b[a(190)]=!1;const n=function(d){alert(d);window[a(183)](window[a(227)].href,"_self")},h=[];let e=!1;const q=function(){const d=h.shift();if(d){var {requestInfo:k,callback:g}=d;b.socket[a(177)](a(164),k,function(p){q();const {status:t}=
p;delete p[a(163)];t?"function"===typeof g?g(p):p.data&&k.worksheet[a(229)](p.data):(p[a(178)]=!0,jSuites[a(193)](p))})}else e=!1};f[a(233)]=function(d,k,g,p){if("undefined"==typeof io)return!1;if(!b[a(190)])if(b[a(192)])"setBorder"!==k&&b[a(185)](!0),d={requestInfo:{worksheet:d[a(198)][a(221)],method:k,args:g?Object[a(215)](g):[]},callback:p},h[a(230)](d),e||(e=!0,q());else console[a(178)](a(175))};f.onevent=function(d,k,g,p){if(d===a(195)&&typeof c[a(195)]===a(218))return c[a(195)](k,g,p)};return function(){typeof io==
a(197)?(b[a(212)][a(226)][a(242)](a(244)),console[a(178)](a(213))):(b.socket=io(c[a(240)],{path:"/"+c[a(210)]+a(169),query:{room:b[a(191)][a(157)],token:c[a(173)]}}),b[a(192)].on(a(179),function(){b.element[a(226)][a(194)](a(244))&&n(a(180))}),b[a(192)].on("disconnect",function(){b[a(212)][a(226)][a(242)](a(244))}),b[a(192)].on(a(174),function(d){typeof c.onchangetitle===a(218)&&c[a(223)](d[a(245)][0])}),b[a(192)].on(a(184),function(d){n(d[a(245)][0])}),b[a(192)].on(a(164),function(d){m[a(225)][a(172)]=
!0;b[a(190)]=!0;b[a(168)]=!0;let k=d.method;var g=d[a(235)];g=b[a(170)](g);if(g=(g=b[a(243)][g])?g:!1)if(k===a(207))g[a(207)](...d[a(245)],d[a(214)]);else if(k===a(205))g[a(205)](d.user,!0);else d=d[a(245)]||[],typeof g[k]==a(218)&&g[k](...d);else typeof b[k]==a(218)&&b[k](...d[a(245)]);b[a(190)]=!1;b.ignorePersistence=!1;m[a(225)].ignore=!1}))}(),f},l=function(b){b&&(b.url&&(c[a(240)]=b[a(240)]),b[a(210)]&&(c[a(210)]=b[a(210)]),b[a(173)]&&(c[a(173)]=b[a(173)]),b[a(223)]&&(c.onchangetitle=b.onchangetitle),
b.onbeforecreate&&(c[a(237)]=b[a(237)]),b.onbeforeload&&(c[a(162)]=b[a(162)]),b[a(195)]&&(c[a(195)]=b[a(195)]))};return l.license=function(){!m&&(m=this)},l.oninit=function(b,f){b.config[a(157)]&&b[a(167)]({client:r})},l.ondestroy=function(b){b[a(192)]&&b[a(192)][a(228)]()},l[a(189)]=function(b){return b?c[a(240)]:c.url+c[a(210)]},l[a(222)]=function(){return c[a(210)]},l[a(176)]=function(b,f){if(typeof io===a(197)){jSuites.loading[a(186)]();let n=c[a(240)]+c.path+a(158),h=document[a(220)],e=document.createElement(a(196));
e[a(188)]=a(204);e[a(159)]=n;e[a(231)]=function(){l[a(166)](b,f);jSuites[a(224)].hide()};h[a(219)](e)}else l.get(b,f)},l[a(166)]=function(b,f){let n=c[a(240)]+c[a(210)]+b[a(157)];if(typeof c.onbeforeload==a(218)){var h=c[a(162)](n);h&&(n=h)}h=function(e){1===e[a(178)]?jSuites[a(193)](e):(typeof c[a(237)]==a(218)&&c.onbeforecreate(e),typeof e[a(191)]!==a(197)&&(e=e[a(191)]),typeof e===a(209)&&(e=JSON.parse(e)),f(e))};c[a(192)]?c[a(192)].emit(a(176),b[a(157)],h):jSuites[a(187)]({url:n,method:a(239),
dataType:"json",beforeSend:function(e){c[a(173)]&&e[a(181)](a(161),a(200)+c.token)},success:h,error:function(){console[a(160)](arguments)}})},l[a(179)]=function(b){b&&l(b);let f=null;typeof io==a(197)?console[a(178)](a(213)):(f=io(b[a(240)],{path:"/"+b.path+a(169),query:{token:b.token||""}}),c[a(192)]=f);return{create:function(n,h){return new Promise(function(e,q){q=a0_0x25ec;f?f.emit(q(236),{guid:n,config:JSON.stringify(h)},function(d){e(d)}):console[q(178)](q(208))})},destroy:function(n,h){f?f[a(177)]("destroy",
n,h):console[a(178)]("Not connect to any server")},disconnect:function(){f[a(228)]();f=null},getSocket:function(){return f}}},l});function a0_0x25ec(a,m){const c=a0_0x308d();return a0_0x25ec=function(r,l){return c[r-157]},a0_0x25ec(a,m)}
function a0_0x308d(){const a="worksheets;disconnected;args;guid;socket.io/socket.io.js;src;log;Authorization;onbeforeload;status;JSS;1443916cEbPOi;get;setPlugins;ignorePersistence;socket.io/;getWorksheet;84596zDrOWl;ignore;token;title;JSS: socket not loaded;load;emit;error;connect;There is a connection issue that seems to be fixed. Reconnecting to your table...;setRequestHeader;658486unAlyw;open;forceRefresh;progress;show;ajax;type;getServerUrl;ignoreCloud;config;socket;notification;contains;onbeforeloadimage;script;undefined;options;51xnfUNq;Bearer ;exports;18UyHbSi;508291cZrBJz;text/javascript;resetBorders;104BKXWNv;setBorder;Not connect to any server;string;path;https://jspreadsheet.com/;element;JSS: socket.io not found;user;values;object;api/;function;appendChild;head;worksheetId;getServerPath;onchangetitle;loading;history;classList;location;disconnect;updateCells;push;onload;3280BvVTxg;persistence;5830623aDingD;worksheet;create;onbeforecreate;535415KqNouD;GET;url;48070oIKOnR;add".split(";");a0_0x308d=
function(){return a};return a0_0x308d()};
